import classNames from 'classnames';

import { DeserializedStorePageAppsBlock } from '@api';

import NextLink from '@shared/common/components/NextLink';
import { sendTagManagerBannerClick } from '@shared/common/providers/GoogleTagManagerProvider';

import StoreAppCard from '@store:web/components/common/AppCard/StoreAppCard';

import { StoreBlockProps } from './StoreBlock';

const StoreAppsBlock = ({
  page,
  block,
  className,
  titleTag: TitleTag = 'span',
}: StoreBlockProps<DeserializedStorePageAppsBlock>) => {
  const { type, title } = block.attributes;
  const apps = block.apps.slice(
    0,
    {
      APPS12: 12,
      APPS6: 6,
      APPS4: 4,
    }[type] || 12,
  );

  return (
    <div
      className={classNames(
        'h-full flex', // making container flex will fix issue when right padding disappear on safari
        className,
      )}
    >
      <div className="flex-shrink-0 w-full" data-qa="apps-block">
        <div className="mb-3 flex justify-between gap-5 items-baseline w-full">
          <TitleTag className={classNames('t-h4 sm:t-h3')} data-qa="apps-block-title">
            {title}
          </TitleTag>

          {!!page && block.attributes.see_all && (
            <NextLink
              href={`/${page}/${block.attributes.link}`}
              className="text-lg font-medium transition-colors text-primary-600 hover:text-primary-700 whitespace-nowrap"
              data-qa="apps-block-see-all-button"
            >
              See all ››
            </NextLink>
          )}
        </div>
        <div
          className={classNames({
            'n-store-container-px h-full flex overflow-x-auto hide-scrollbar store-container-px':
              type !== 'APPS4',
          })}
        >
          {/*
            NOTE: h-fit makes content to clip properly because of negative margin
            (intended behavior to hide bottom border of the card)
          */}
          <div
            className={classNames(
              'overflow-hidden h-fit min-w-full flex-grow-1 flex-shrink-0 basis-auto',
              {
                'lg:w-full': type === 'APPS12',
                'md:w-full': type === 'APPS6',
                'w-full': type === 'APPS4',
              },
            )}
          >
            <ul
              className={classNames('grid -mx-2 -mb-3 auto-rows-[6.75rem]', {
                'grid-cols-[repeat(3,calc(100vw-30px))] sm:grid-cols-[repeat(3,400px)] lg:grid-cols-3 2xl:grid-cols-4':
                  type === 'APPS12',
                'grid-cols-[repeat(2,calc(100vw-30px))] sm:grid-cols-[repeat(2,400px)] md:grid-cols-2':
                  type === 'APPS6',
                'grid-cols-[repeat(auto-fill,_minmax(min(100%,_max(240px,_50%)),_1fr))]':
                  type === 'APPS4',
              })}
            >
              {apps.map((app) => {
                return (
                  <StoreAppCard
                    As="li"
                    className="py-3 px-2 border-b border-neutral-600/10"
                    app={app}
                    key={app.id}
                    onClick={() => {
                      sendTagManagerBannerClick({
                        bannerId: app.attributes.name,
                      });
                    }}
                    data-qa="app-inside-block"
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreAppsBlock;
