'use client';
import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

import { useAuth } from '@store:web/components/providers/AuthProvider';

export const useUserAdSettings = () => {
  const { user } = useAuth();
  const { enabledAdForUser = false } = useFeatureToggles();
  return {
    enabled: enabledAdForUser && !!user?.attributes?.adSettings?.enabled,
    counters: {
      impressions: user?.attributes?.adSettings?.counters?.impressions || 0,
    },
  };
};
