import classNames from 'classnames';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import ImageProxy from '@next-image-proxy';

import { DeserializedStorePageCarouselBlock } from '@api';

import NextLink from '@shared/common/components/NextLink';

import { StoreBlockProps } from '../StoreBlock';
import AppSlide from './AppSlide';
import HotOfferSlide from './HotOfferSlide';

const StoreCarouselAppBlock = ({ block }: StoreBlockProps<DeserializedStorePageCarouselBlock>) => {
  const {
    id,
    attributes: { items },
  } = block;

  return (
    <Swiper
      key={id}
      modules={[Autoplay]}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      spaceBetween={10}
      slidesPerView="auto"
      className="!mx-0"
      wrapperClass="flex items-center aspect-[550/285]"
    >
      {items?.map((item) => {
        const showSlideInfo =
          Boolean(item.appId) ||
          Boolean(item.hotOfferId) ||
          Boolean(item.blockOptionTitle) ||
          Boolean(item.blockOptionDescription);

        const isAppSlide = item.appId || item.blockOptionTitle || item.blockOptionDescription;
        const isHotOfferSlide = item.hotOfferId && !isAppSlide;

        return (
          <SwiperSlide key={item.id} className="rounded-[20px] border border-primary-200">
            <NextLink href={item.blockLink} className="flex flex-col h-full">
              <div
                className={classNames('rounded-2xl overflow-hidden flex-1', {
                  'rounded-b-none': showSlideInfo,
                })}
              >
                <ImageProxy
                  alt={item.blockOptionAlt || 'Magic Store Banner'}
                  sizes="(min-width: 768px) 50vw, (min-width: 1400px) 33vw, 100vw"
                  width={800}
                  height={400}
                  quality={80}
                  className="w-full h-full object-cover"
                  src={item.image}
                />
              </div>
              <AppSlide
                item={item}
                className={classNames({ hidden: !showSlideInfo || !isAppSlide })}
              />
              <HotOfferSlide
                item={item}
                className={classNames({ hidden: !showSlideInfo || !isHotOfferSlide })}
              />
            </NextLink>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default StoreCarouselAppBlock;
