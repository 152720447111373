import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/apps/store-web/components/pages/StorePage/StorePage.tsx");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/ReactQuery/QueryProviderSsr.tsx");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/hooks/useTelegramMiniAppSdk.ts");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/circle-email.svg");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/circle-linked-in.svg");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/circle-reddit.svg");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/circle-telegram.svg");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/circle-twitter.svg");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/evm-light.svg");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/facebook.svg");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/metamask.svg");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/okx.svg");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/phantom.svg");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/trust-wallet.svg");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/wallet-connect.svg");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/@tanstack/react-query/build/modern/useMutation.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/@tanstack/react-query/build/modern/useQueries.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/@tanstack/react-query/build/modern/useQuery.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/context.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useAccount.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useAccountEffect.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useBalance.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useBlock.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useBlockNumber.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useBlockTransactionCount.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useBytecode.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useCall.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useChainId.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useChains.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useClient.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useConfig.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useConnect.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useConnections.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useConnectorClient.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useConnectors.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useDeployContract.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useDisconnect.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useEnsAddress.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useEnsAvatar.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useEnsName.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useEnsResolver.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useEnsText.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useEstimateFeesPerGas.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useEstimateGas.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useEstimateMaxPriorityFeePerGas.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useFeeHistory.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useGasPrice.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useInfiniteReadContracts.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/usePrepareTransactionRequest.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useProof.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/usePublicClient.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useReadContract.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useReadContracts.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useReconnect.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useSendTransaction.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useSignMessage.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useSignTypedData.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useSimulateContract.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useStorageAt.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useSwitchAccount.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useSwitchChain.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useToken.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useTransaction.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useTransactionConfirmations.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useTransactionCount.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useTransactionReceipt.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useVerifyMessage.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useVerifyTypedData.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useWaitForTransactionReceipt.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useWalletClient.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useWatchAsset.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useWatchBlockNumber.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useWatchBlocks.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useWatchContractEvent.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useWatchPendingTransactions.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hooks/useWriteContract.js");
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/node_modules/wagmi/dist/esm/hydrate.js")